import React from "react";
import "./TechnicalTraining.css";
import { AiFillStar } from "react-icons/ai";
import TrainingCard from "../../../components/cards/TrainingCard";
import AlumniSlider from "../../../components/cardsliders/AlumniSlider";
import { NavLink } from "react-router-dom";
import { FaLaptopCode } from "react-icons/fa";
import { TbTopologyComplex } from "react-icons/tb";

const TechnicalTraining = () => {
  return (
    <>
      <section id="training-top">
        <div className="training-top container">
          <div className="training-top-left">
          <p>#1 Online Bootcamp</p>
          <p>Customized Courses as per Industry Needs</p>
          <p>Live Interactive Classes</p>
          <p>100% Job Assistance for Job promotion / New Job</p>
          <a className="cta" href="#training-programs">
            Explore Programs
          </a>
          </div>
          <div className="training-top-righy"></div>
        </div>
      </section>

<section id="top-mid">
        <div className="top-mid">
          <div className="top-mid-text">
          <h1 className="top-mid-title">Trending Courses</h1>
          <p>Get Certified with our Programs</p>
          
          </div>
          <div className="course-cards">
            <NavLink to="/mern" className="course-item bg-green">
              <FaLaptopCode className="course-icon" />
              <p>Full Stack Web Development</p>
            </NavLink>
            <NavLink to="/javafull" className="course-item bg-cyan">
              <FaLaptopCode className="course-icon" />
              <p>Java Full Stack Development</p>
            </NavLink>
            <NavLink to="/aiml" className="course-item bg-leafgreen">
              <FaLaptopCode className="course-icon" />
              <p>AI&ML</p>
            </NavLink>
            <NavLink to="/cloud" className="course-item bg-green">
              <FaLaptopCode className="course-icon" />
              <p>Cloud Architect & AWS</p>
            </NavLink>
            <NavLink to="/datascience" className="course-item bg-green">
              <TbTopologyComplex className="course-icon" />
              <p>Python & Data Science</p>
            </NavLink>
            <NavLink to="/devops" className="course-item bg-cyan">
              <TbTopologyComplex className="course-icon" />
              <p>DevOps</p>
            </NavLink>
          </div>
        </div>
      </section>

      <main className="container training-main">
        <section className="partners-training-section section container">
          <h4 className="heading4 center">Partnering with Leading Universities & Companies</h4>
          <div className="training-img-container">
            <img src="assets/training/pdps.jpeg" alt="" />
            <img src="assets/training/dist.jpg" alt="" />
            <img src="assets/training/sku.jpg" alt="" />
          </div>
        </section>

        {/* Start programs-container Section  */}

        <h3 className="heading3">Our Training Insights</h3>
        <section className=" training-programs-container section container">
          <div className="content">
            <div className="content-left">
              <h3>Mission</h3>
              <p>
                We are dedicated to transforming individuals into
                skilled professionals by delivering top-notch technical training
                that empowers their careers
              </p>
              <h3>Guidance</h3>
              <p>
                We provide hands-on teaching approach, active
                learning and practical skill development to ensure our students
                are well-prepared for real-world challenges in the technology
                sector.
              </p>
            </div>

            <figure className="content-right">
              <img src="assets/service/web.jpg" alt="Shiv Shakti Gurukulam" />
            </figure>
          </div>
        </section>

        {/* End programs-container Section  */}

        {/* Start programs-container Section  */}
        <h2 className="heading2">Certification Programs</h2>
        <section id="training-programs" className="training-pg-certifications-container section ">
          <ul>
            <li>
              <AiFillStar />
              Live Interactive Classes
            </li>

          </ul>
          <div className=" training-cards">
            <TrainingCard
              src={"assets/training/fullstack.png"}
              title={"Full Stack Web Development"}
              text={
                "The full MERN stack training offered by us is a comprehensive program designed to equip participants with the skills and knowledge needed to become proficient MERN stack developers. MERN stands for MongoDB, Express.js, React, and Node.js, which are the key technologies used to build modern web applications."
              }
              link={'/mern'}
            />
            
            <TrainingCard
              src={"assets/training/javafull.jpeg"}
              title={"Java Full Stack Development"}
              text={
                "This Java Full Stack Developer course is designed to introduce you to front-end, middleware, and back-end web developement technologies. You will learn to build an end-to-end application, test and deploy code, store data using MongoDB, and much more."
              }
              link={'/javafull'}
            />
            <TrainingCard
              src={"assets/training/aiml.jpg"}
              title={"AI & ML"}
              text={
                "We offer an AI and ML training program designed to empower individuals with the knowledge and skills to excel in the fields of Artificial Intelligence and Machine Learning. "
              }
              link={'/aiml'}
            />
          </div>
          <div className=" training-cards">
            <TrainingCard
              src={"assets/training/aws.jpg"}
              title={"AWS Cloud Architect"}
              text={
                "AWS Cloud Architect course typically covers a comprehensive set of topics to equip participants with the skills and knowledge needed to design and implement scalable, secure, and high-performance solutions on the AWS platform."
              }
              link={'/cloud'}
            />
            <TrainingCard
              src={"assets/training/datascience.png"}
              title={"Python & Data Science"}
              text={
                "A Data Science course teaches skills in programming (e.g., Python), statistical analysis, machine learning, and data visualization. It often includes a hands-on project for real-world application and covers ethical considerations. The goal is to equip individuals to analyze data effectively and make informed decisions."
              }
              link={'/datascience'}
            />
            <TrainingCard
              src={"assets/training/devops.png"}
              title={"DevOps"}
              text={
                "DevOps Fundamentals covers essential principles and tools for seamless collaboration between development and operations. Participants learn key concepts such as continuous integration, deployment, infrastructure as code, and security."
              }
              link={'/devops'}
            />
          </div>
          <div className=" training-cards">
            <TrainingCard
              src={"assets/training/aws.jpg"}
              title={"UI / UX Design"}
              text={
                "AWS Cloud Architect course typically covers a comprehensive set of topics to equip participants with the skills and knowledge needed to design and implement scalable, secure, and high-performance solutions on the AWS platform."
              }
              link={'/cloud'}
            />
            <TrainingCard
              src={"assets/training/cc++.jpg"}
              title={"C++ Advanced"}
              text={
                "A Data Science course teaches skills in programming (e.g., Python), statistical analysis, machine learning, and data visualization. It often includes a hands-on project for real-world application and covers ethical considerations. The goal is to equip individuals to analyze data effectively and make informed decisions."
              }
              link={'/datascience'}
            />
            <TrainingCard
              src={"assets/training/devops.png"}
              title={"Automation Testing"}
              text={
                "DevOps Fundamentals covers essential principles and tools for seamless collaboration between development and operations. Participants learn key concepts such as continuous integration, deployment, infrastructure as code, and security."
              }
              link={'/devops'}
            />
          </div>
        </section>
        {/* End programs-container Section  */}

        {/* Start programs-container Section  */}
        {/* <h2 className="heading2">Other Training Certificate Programs</h2>
        <section className="training-certificate-programs-container section ">
          <div className="jcse training-certificate-programs-cards">
            <Card key={"Secondary"} className="mb-2 simplecard">
              <Card.Header
                style={{ backgroundColor: "orange" }}
                className="simplecard-title"
              >
                Java Training
              </Card.Header>
              <Card.Body className="simplecard-desc">
                <Card.Text>Our Java training program provides comprehensive instruction in Java programming, equipping participants with the expertise needed to develop robust and scalable Java applications. Our training combines theory with practical exercises to ensure students gain valuable skills for success in the world of Java development.</Card.Text>
              </Card.Body>
            </Card>
            <Card key={"Secondary"} className="mb-2 simplecard">
              <Card.Header
                style={{ backgroundColor: "#00e1ff" }}
                className="simplecard-title"
              >
                Python Training
              </Card.Header>
              <Card.Body className="simplecard-desc">
                <Card.Text>We offer Python training that covers the fundamentals and advanced aspects of Python programming. Our training program focuses on hands-on learning and real-world applications, enabling participants to become proficient Python developers and problem solvers.</Card.Text>
              </Card.Body>
            </Card>
            <Card key={"Secondary"} className="mb-2 simplecard">
              <Card.Header
                style={{ backgroundColor: "pink" }}
                className="simplecard-title"
              >
                Frontend Development
              </Card.Header>
              <Card.Body className="simplecard-desc">
                <Card.Text>Our Frontend Development training equips individuals with the latest tools and techniques in web development. Participants learn to create responsive and user-friendly web interfaces, gaining the skills needed to excel in the dynamic world of frontend development.</Card.Text>
              </Card.Body>
            </Card>
          </div>
        </section> */}
        {/* End programs-container Section  */}

        {/* Start training-hiring-partners Section  */}

        {/* <h2 className="heading2">Our Hiring Partners</h2>
        <section className="training-hiring-partners-container section">
          <div className="training-hiring-partners-left">
            <h3>Heading</h3>
          </div>
          <div className="training-hiring-partners-right">
            <img src="assets/companies/infosys.png" alt="" />
            <img src="assets/companies/infosys.png" alt="" />
            <img src="assets/companies/infosys.png" alt="" />
            <img src="assets/companies/infosys.png" alt="" />
          </div>
        </section> */}
        {/* End training-hiring-partners Section  */}

        {/* Start training-alumni-talk Section  */}

        <h2 className="heading2">Our Alumni Talk & Feedback</h2>
           <section className="section"> <AlumniSlider /></section>
        {/* End training-training-alumni-talk Section  */}
      </main>
    </>
  );
};

export default TechnicalTraining;
