import React from "react";
import "./Career.css";
import AccordionComp from "../../components/AccordionComp/AccordionComp";
import { BiSolidCustomize } from "react-icons/bi";
import IconTextCard from "../../components/cards/IconTextCard";
import ApplyJob from "../../components/contact/ApplyJob";


const Career = () => {
  return (
    <>
      <section className="career-top-section">
        <div className="career-top-left">
        <h1>Career inside ICOESS</h1>
            <p>
              Our comprehensive web development offerings cover everything from
              cutting-edge website design to the development of robust back-end
              systems. We take pride in creating visually stunning,
              user-friendly, and responsive websites that engage your audience
              effectively and help achieve your online goals.
            </p>
            <a  href='#apply'>
            <button className="cta">Apply Here</button> 
          </a>
            
          </div>
          <div className="career-top-right">
          </div>
      </section>



      <section className="section">
        <div className="card2-item article">
          <div className="card2-info">
            <h1>Work with us</h1>
            <p>
              Join our dynamic IT team for a cutting-edge, collaborative work
              environment that fosters innovation and growth. Enjoy competitive
              compensation, challenging projects, and a supportive culture that
              prioritizes work-life balance and professional development. Join
              us to be at the forefront of technological advancements and make a
              meaningful impact in the world of IT.
            </p>
            <a href="#currentOpenings" className="cta">
              Current Openings
            </a>
          </div>
          <div className="card2-img">
            <img src="assets/career/team.jpg" alt="img" />{" "}
          </div>
        </div>
      </section>
      <main className="main container career-page">
        <h1 className="heading3" id="currentOpenings">
          Current Openings
        </h1>
        <section className="section career-oppotunities">
          <AccordionComp
            title={"Web Designer"}
            titleWidth={'33%'}
            title2={"0-1 years"}
            panelprop={"panel1"}
            jdhead={'Job Description'}
            jdli1={'Candidate must have 0-3 Yrs of Experience in Web Design and Development.'}
            jdli2={'Candidate must have a good sense of design and  development aesthetics.'}
            jdli3={'Should be able to interact with Client and get their updates done. Strong written and verbal communication skills in English'}
            jdli4={'Graphic and Website Designer rolled into one creative and confident individual.'}
            jdli5={'Ability to meet project delivery timelines.'}
            jdhead2={'Technical Skills:'}
            jd2li1={'Advanced knowledge of HTML, CSS, jQuery/Javascript, Flash , Graphic Design is mandatory.'}
            jd2li2={'Knowledge of ASP.net, MsSQL, PHP and MySQL'}
            jdhead3={'Mode of Work:'}
            jd3li1={'Work from Home'}
            btn={'true'}
          />
          <AccordionComp
            title={"Web Developer"}
            titleWidth={'33%'}
            title2={"0-3 years"}
            panelprop={"panel1"}
            jdhead={'Job Description'}
            jdli1={'Candidate must have 0-3 Yrs of Experience in Web Design and Development.'}
            jdli2={'Candidate must have a good sense of design and  development aesthetics.'}
            jdli3={'Should be able to interact with Client and get their updates done. Strong written and verbal communication skills in English'}
            jdli4={'Graphic and Website Designer rolled into one creative and confident individual.'}
            jdli5={'Ability to meet project delivery timelines.'}
            jdhead2={'Technical Skills:'}
            jd2li1={'Advanced knowledge of HTML, CSS, jQuery/Javascript, Flash , Graphic Design is mandatory.'}
            jd2li2={'Knowledge of ASP.net, MsSQL, PHP and MySQL'}
            jdhead3={'Mode of Work:'}
            jd3li1={'Work from Home'}
            btn={'true'}
          />
          <AccordionComp
            title={"Business Development Executive"}
            titleWidth={'33%'}
            title2={"0-2 years"}
            panelprop={"panel1"}
            jdhead={'Job Description'}
            jdli1={'Candidate must have 0-3 Yrs of Experience in Web Design and Development.'}
            jdli2={'Candidate must have a good sense of design and  development aesthetics.'}
            jdli3={'Should be able to interact with Client and get their updates done. Strong written and verbal communication skills in English'}
            jdli4={'Graphic and Website Designer rolled into one creative and confident individual.'}
            jdli5={'Ability to meet project delivery timelines.'}
            jdhead2={'Technical Skills:'}
            jd2li1={'Advanced knowledge of HTML, CSS, jQuery/Javascript, Flash , Graphic Design is mandatory.'}
            jd2li2={'Knowledge of ASP.net, MsSQL, PHP and MySQL'}
            jdhead3={'Mode of Work:'}
            jd3li1={'Work from Home'}
            btn={'true'}
          />
        </section>

        <h1 className="heading3">Benefits of joining us</h1>
        <section className="section center column">
        <div className="row">
            <IconTextCard
              icon={<BiSolidCustomize />}
              heading={"Career Growth Opportunities"}
              text={
                "Attractive Websites are doors of your Business. Our Team of Experts provides the custom build, Sophisticated & Full Featured Websites. Our Full Stack Developer team builds Robust Web Applications. So, if you are looking for E-Com web Sites, Shopping portals, Web Applications? Just knock the door of us, Tell your requirements and rest leave to us."
              }
            />
            <IconTextCard
              icon={<BiSolidCustomize />}
              heading={"Flexible Working Hours"}
              text={
                "Our company offers outsourced web design and development services. We have a dedicated team of experts who can handle your web projects efficiently. By outsourcing your web design and development needs to us, you can save time and resources while benefiting from our expertise.You can streamline your web projects and ensure a strong online presence."
              }
            />
            <IconTextCard
              icon={<BiSolidCustomize />}
              heading={"Performance Based Upraisals"}
              text={
                "Our company specializes in outsourced mobile app development services. We have a skilled team of experts ready to take on your mobile app projects efficiently and cost-effectively. We prioritize user-friendly design and feature-rich functionality, ensuring your mobile app aligns with your objectives.Reduce overhead, and bring your mobile app ideas to life."
              }
            />
          </div>
          
        </section>

        <section id="apply" className="section apply-job-form">
          <ApplyJob/>
        </section>
      </main>
    </>
  );
};

export default Career;
