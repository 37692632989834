import React from "react";
import "./Contact.css";
import { useState } from "react";
import axios from "axios";
import AlertComp from "../Alert/Alert";

const ApplyJob = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [jobtitle, setJobtitle] = useState("");
  const [experience, setExperience] = useState("");
  const [resume, setResume] = useState("");
  const [response, setResponse] = useState();

  async function submit(e) {
    e.preventDefault();

    try {
      axios
      .post("https://icoesolutionserver.onrender.com/api/jobApply", {
        name,
        phone,
        email,
        department,
        jobtitle,
        experience,
        resume,
      })
      .then((resp) => {
        setResponse(resp.data.message);
        setName('')
        setPhone('')
        setEmail('')
        setDepartment('')
        setJobtitle('')
        setExperience('')
        setResume('')
      }).catch((e)=>{
        alert("Something went wrong")
        console.log(e)
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <section className="apply-job">
        <div className="home-services-header">
          <h1 className="section-title">
            <span>Apply</span> Here
          </h1>
        </div>

        <div class="">
          
          <form className="form" action="POST" onSubmit={submit}>
            <input
              aria-label="Name"
              name="name"
              type="text"
              className="form-control"
              placeholder="Enter Full Name"
              aria-required="true"
              value={name}
              onChange={(e)=>{setName(e.target.value)}}
            />

            <input
              aria-label="Email"
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter Email"
              aria-required="true"
              value={email}
              onChange={(e)=>{setEmail(e.target.value)}}
            />

            <input
              aria-label="Mobile Number"
              type="number"
              name="mobile"
              className="form-control"
              placeholder="Mobile Number"
              aria-required="true"
              value={phone}
              onChange={(e)=>{setPhone(e.target.value)}}
            />

            <select name="areaOfInterest" id="areaOfInterest" onChange={(e)=>{setDepartment(e.target.value)}} value={department}>
              <option value="">Select Department</option>
              <option value="IT">IT</option>
              <option value="Finance">Finance</option>
              <option value="HR">HR</option>
              <option value="Trainer">Trainer</option>
              <option value="Sales/Marketing">Sales/Marketing</option>
            </select>

            <select name="areaOfInterest" id="areaOfInterest" onChange={(e)=>{setJobtitle(e.target.value)}} value={jobtitle}>
              <option value="">Select Job Title</option>
              <option className="option" value="Web Designer">
                Web Designer
              </option>
              <option className="option" value="Web Developer">
                Web Developer
              </option>
              <option className="option" value="Business Development Executive">
                Business Development Executive
              </option>
            </select>

            <input
              aria-label="Experience"
              type="text"
              name="experience"
              className="form-control"
              placeholder="Experience in Years"
              aria-required="true"
              onChange={(e)=>{setExperience(e.target.value)}}
              value={experience}
            />

            <textarea
              aria-label="Message"
              name="resume"
              className="form-control mt-2"
              placeholder="Paste Your Resume"
              rows={10}
              aria-required="true"
              onChange={(e)=>{setResume(e.target.value)}}
              value={resume}
            ></textarea>
          {response && (<AlertComp message={response}/>)}

            <button type="submit" aria-label="Create Account">
              Apply
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ApplyJob;
