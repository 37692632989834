import React from "react";
import "./Cards.css";

const TechStackCard = ({ icon, heading, li1, li2, li3, li4 }) => {
  return (
    <>
      <div className="tech-stack-card">
        <div className="head">
        {icon}
        <h3>{heading}</h3>
        </div>
        {li1&&<ul>
          {li1 && <li>{li1}</li>}
          {li2 && <li>{li2}</li>}
          {li3 && <li>{li3}</li>}
          {li4 && <li>{li4}</li>}
        </ul>}
      </div>
    </>
  );
};

export default TechStackCard;
