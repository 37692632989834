import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import {
  FaFacebookSquare,
  FaLinkedin,
  FaYoutubeSquare,
} from "react-icons/fa";

const Footer = () => {

  const subscribeNewsLetter = () =>{

  }
  return (
    <>
      {/* ==================== FOOTER ==================== */}
      <footer className="footer">
        <div className="footer__container container grid">
          <div className="footer__content grid">
            <div className="footer__data">
              <h3 className="footer__subtitle">Company</h3>
              <ul>
                <li className="footer__item">
                  <NavLink to="/about" className="footer__link">
                    About Us
                  </NavLink>
                </li>
                <li className="footer__item">
                  <NavLink to="/portfolio" className="footer__link">
                    Portfolio
                  </NavLink>
                </li>
                <li className="footer__item">
                  <a href="/career" className="footer__link">
                    Become a member
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer__data">
              <h3 className="footer__subtitle">Pages</h3>
              <ul>
                <li className="footer__item">
                  <NavLink to="/" className="footer__link">
                    Home
                  </NavLink>
                </li>

                <li className="footer__item">
                  <NavLink to="/webd" className="footer__link">
                    Web Development
                  </NavLink>
                </li>
                <li className="footer__item">
                  <NavLink to="/mobiled" className="footer__link">
                    Mobile App Development
                  </NavLink>
                </li>
                <li className="footer__item">
                  <NavLink to="/technicalTraining" className="footer__link">
                    Technical Training
                  </NavLink>
                </li>
                <li className="footer__item">
                  <NavLink to="/SwDevOutsourcing" className="footer__link">
                    S/w Dev & OutSourcing
                  </NavLink>
                </li>
                <li className="footer__item">
                  <NavLink to="/itStaffing" className="footer__link">
                    IT Staffing & Smart Hire
                  </NavLink>
                </li>
                <li className="footer__item">
                  <NavLink to="/EducationSupport" className="footer__link">
                    Education Support
                  </NavLink>
                </li>
                <li className="footer__item">
                  <NavLink to="/career" className="footer__link">
                    Career
                  </NavLink>
                </li>
                <li className="footer__item">
                  <NavLink to="/contact" className="footer__link">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>

            {/* <div className="footer__data">
              <h3 className="footer__subtitle">Blogs</h3>
              <ul>
                <li className="footer__item">
                  <a href="/" className="footer__link">
                    B1
                  </a>
                </li>
                <li className="footer__item">
                  <a href="/" className="footer__link">
                    B2
                  </a>
                </li>
                <li className="footer__item">
                  <a href="/" className="footer__link">
                    B3
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="footer__data">
              {/* <h3 className="footer__subtitle">Subscribe Newsletter</h3>
              <form class="footer-email flex" onSubmit={subscribeNewsLetter}>
                <input type="text" placeholder="Email" />
                <button class="footer-subscribe-btn flex" type="submit">
                  <AiOutlineMail className="subscribe-img" />
                  <p>Subscribe</p>
                </button>
              </form> */}

              <div class="footer-socials">
        
              <a href="https://www.facebook.com/icoess?mibextid=LQQJ4d" target="_icoess">
                <FaFacebookSquare className="facebook" />
              </a>

              <a href="https://www.linkedin.com/company/icoess/" target="_icoess">
                <FaLinkedin  className="linkedin" />
              </a>

              <a href="https://youtube.com/@icoess?si=TGEg_Ru4cwpHadR7" target="_icoess">
                <FaYoutubeSquare className="youtube" />
              </a>
              </div>
            </div>
          </div>

          <div className="footer__rights">
            <p className="footer__copy">
              &#169; 2023 ICOESS Solutions . All rigths reserved.
            </p>
            <div className="footer__terms">
              <a href="/terms" className="footer__terms-link">
                Terms & Agreements
              </a>
              <a href="/privacyPolicy" className="footer__terms-link">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
