import React from 'react'
import './Contact.css'
import { NavLink } from "react-router-dom";


const ContactSection = ({text,src}) => {
  return (
    <>
    <article id="contact-section" className="contact-section ">
          <div className="contact-section-left">
            <img src="assets/contact.jpg" alt="" />
          </div>

          <div className="contact-section-right">
            <h1>
              {text}
            </h1>
            <NavLink className="cta" to={"/contact"}>
            Contact Us
                </NavLink>
          </div>
        </article>

    </>
  )
}

export default ContactSection