import React from "react";
import "./Contact.css";
import axios from "axios";
import { useState } from "react";
import AlertComp from "../Alert/Alert";


const ApplyCourse = () => {
  const [name, setName] = useState("");
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState("");
  const [course, setCourse] = useState("");
  const [response, setResponse] = useState();

  async function submit(e) {
    e.preventDefault();

    try {
      await axios.post("https://icoesolutionserver.onrender.com/api/course-email", { name, email,phone,course })
      .then(resp=>{
        setResponse(resp.data)
        setName("")
        setPhone("")
        setEmail("")
        setCourse("")
	  })
      .catch((e)=>{
        alert('Something went wrong')
        console.log(e)
      })
    } 
    catch (e) {
        console.log(e)
    }
  }

  return (
    <>
      <section className="apply-job">
        

        <div class="">
          <form className="form" action="POST" onSubmit={submit}>
            <input
              aria-label="Name"
              name="name"
              type="text"
              className="form-control"
              placeholder="Enter Full Name"
              required
              onChange={(e)=>{setName(e.target.value)}}
              value={name}
            />

            <input
              aria-label="Email"
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter Email"
              required
              onChange={(e)=>{setEmail(e.target.value)}}
              value={email}
            />

            <input
              aria-label="Mobile Number"
              type="number"
              name="mobile"
              className="form-control"
              placeholder="Mobile Number"
              required
              onChange={(e)=>{setPhone(e.target.value)}}
              value={phone}
            />

            <select name="areaOfInterest" id="areaOfInterest" onChange={(e)=>{setCourse(e.target.value)}} value={course}>
            <option value="">Select Course</option>
                <option value="Full Stack Web Development">Full Stack Web Development</option>
                <option value="AI & ML">AI & ML</option>
                <option value="Java Full Stack">Java Full Stack</option>
                <option value="Cloud Architect & AWS">Cloud Architect & AWS</option>
                <option value="Python & Data Science">Python & Data Science</option>
            </select>

            {response && (<AlertComp message={response}/>)}

            <button type="submit" aria-label="Create Account">
              Apply
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ApplyCourse;
