import React from "react";
import "../About/About.css";

const TermsCondition = () => {
  return (
    <div>
      <section className="about-section">
        <div className="container">
          <div className="column">
            <div className="sec-title">
              <h2>Title</h2>
              <div className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Corporis, sed. Vero rem harum repellat eaque in a porro
                quibusdam, fugit iusto consectetur tempora earum nostrum.
              </div>
            </div>
            <div className="sec-title">
              <h2>Title</h2>
              <div className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Corporis, sed. Vero rem harum repellat eaque in a porro
                quibusdam, fugit iusto consectetur tempora earum nostrum.
              </div>
            </div>
            <div className="sec-title">
              <h2>Title</h2>
              <div className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Corporis, sed. Vero rem harum repellat eaque in a porro
                quibusdam, fugit iusto consectetur tempora earum nostrum.
              </div>
            </div>
            <div className="sec-title">
              <h2>Title</h2>
              <div className="text">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Corporis, sed. Vero rem harum repellat eaque in a porro
                quibusdam, fugit iusto consectetur tempora earum nostrum.
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsCondition;
