import React from "react";
import TopSection from "../../../components/cards/top/TopSection";
import TextComponent from "../../../components/TextComponent/TextComponent";
import "./EducationalSupport.css";
import ContactSection from "../../../components/contact/ContactSection";
import { NavLink } from "react-router-dom";

const EducationalSupport = () => {
  return (
    <>
      <div className="education-top">
        <TopSection
          heading={"Education Support"}
          text={"State Compensatory Education (SCE)"}
        />
      </div>

      <main className="container">
        {/* Start Why Training Support Section  */}
        <h1 className="heading3">Why Training Support</h1>
        <TextComponent
          para1={
            "India is on the move. While a lot of different sectors have started growing at good pace, it is IT sector which has really been the driver of growth for last decade or more, and has the potential to lead India for another couple of decades at the very least. There is one small hitch though. We don’t have enough trained manpower."
          }
          para2={
            "Education represents the stepping stone to improve the quality of life, The corporate social responsibility initiatives in education is to transform lives through the continuous enhancement of knowledge and empowerment.Education represents the stepping stone to improve the quality of life, The corporate social responsibility initiatives in education is to transform lives through the continuous enhancement of knowledge and empowerment."
          }
          para3={
            "ICOESS aimes to support the functioning of many schools & Colleges in the vicinity of it’s facilities or at our partners premises across the country."
          }
        />
        {/* End Why Training Support Section  */}

        <section className="section education-cards">
          <div className="card2-item article">
            <div className="card2-info">
              <h1>State Compensatory Education (SCE)</h1>
              <p>
                Providing comprehensive support for state compensatory education
                (SCE) programs. We work closely with educational institutions
                and state authorities to develop tailored solutions that address
                the unique needs of at-risk students and those from
                disadvantaged backgrounds. Our services encompass targeted
                intervention programs, specialized curriculum development, and
                resource allocation to ensure that every student has an equal
                opportunity to succeed. Through our dedicated SCE support, we
                aim to foster an inclusive educational environment that promotes
                academic excellence and equitable outcomes for all students.
              </p>
            </div>
            <div className="card2-img">
              <img src="assets/EducationSupport/second2.jpg" alt="img" />{" "}
            </div>
          </div>
          <div className="card2-item article">
            <div className="card2-info">
              <h1>Rural Development Support</h1>
              <p>
                We aim to bridge the educational gap in rural areas by
                implementing tailored programs and initiatives. With a focus on
                enhancing access to quality education, we offer resources,
                infrastructure development, and educational tools to empower
                rural communities. Our holistic approach emphasizes
                skill-building, literacy enhancement, and promoting educational
                opportunities for all. Through our commitment to rural
                development in education, we strive to create a more equitable
                and empowered society.
              </p>
            </div>
            <div className="card2-img">
              <img src="assets/EducationSupport/rural.jpg" alt="img" />{" "}
            </div>
          </div>
        </section>

        {/* Start What we offer section*/}
        {/* <section className="what-weoffer-container section container">
          <div className="row jcsb mb-4">
            <img src="assets/EducationSupport/first.jpg" alt="" />
            <img src="assets/EducationSupport/second.jpg" alt="" />
            <img src="assets/EducationSupport/third.jpg" alt="" />
          </div>
          <div className="row jcsb">
            <img src="assets/EducationSupport/fourth.jpg" alt="" />
            <img src="assets/EducationSupport/fifth.jpg" alt="" />
            <img src="assets/EducationSupport/sixth.jpg" alt="" />
          </div>
        </section> */}
        {/* End What we offer section*/}

        {/* Start rural Section */}
        {/* <section className="section rural-container">
          <img src="assets/EducationSupport/rural.jpg" alt="" />
        </section> */}
        {/* End rural Section */}
      </main>

      {/* Start ContactSection */}
      <ContactSection
        text={
          "ICOESS aimes to support the functioning of many schools & Colleges in the vicinity of it’s facilities or at our partners premises across the country."
        }
      />
      {/* End ContactSection */}
    </>
  );
};

export default EducationalSupport;
