import React from "react";
import "./Contact.css";
import axios from "axios";
import { useState } from "react";

import AlertComp from "../Alert/Alert";

const ApplyCourseSmall = () => {
  const [name, setName] = useState("");
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState("");
  const [course, setCourse] = useState("");
  const [response, setResponse] = useState();


  async function submit(e) {
    e.preventDefault();

    try {
      await axios.post("https://icoesolutionserver.onrender.com/api/course-email", { name, email,phone,course })
      .then(resp=>{
        setResponse(resp.data)
        setName("")
        setPhone("")
        setEmail("")
        setCourse("")
	  })
      .catch((e)=>{
        alert('Something went wrong')
        console.log(e)
      })
    } 
    catch (e) {
        console.log(e)
    }
  }

  return (
    <>
      <section className="apply-job">
        <div className="contact-card">
          <div className="center column">
            <h2 className="heading2">Free Career Counselling</h2>
            <p className="para2">
              Dont be shy let us know if you have any questions
            </p>
            <form action="POST" className="contact-form" onSubmit={submit}>
              <input type="text" placeholder="Full Name" name="name" id="" onChange={(e)=>{setName(e.target.value)}} value={name} required/>
              <input type="number" placeholder="Mobile Number" name="number" id="" onChange={(e)=>{setPhone(e.target.value)}} value={phone} required/>
              <input type="email" placeholder="Email" name="email" id="" onChange={(e)=>{setEmail(e.target.value)}} value={email} required/>
              <select name="course" id="course" onChange={(e)=>{setCourse(e.target.value)}} value={course}>
                <option value="">Select Course</option>
                <option value="Full Stack Web Development">Full Stack Web Development</option>
                <option value="AI & ML">AI & ML</option>
                <option value="Java Full Stack">Java Full Stack</option>
                <option value="Cloud Architect & AWS">Cloud Architect & AWS</option>
                <option value="Python & Data Science">Python & Data Science</option>
                <option value="DevOps">DevOps</option>
              </select>
              {response && (<AlertComp message={response}/>)}

              <button type="submit" className={" cta"}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApplyCourseSmall;
