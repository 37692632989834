/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Course.css";
import { VscPass } from "react-icons/vsc";
import AccordionComp from "../../../../components/AccordionComp/AccordionComp";
import ApplyCourse from "../../../../components/contact/ApplyCourse";
import CourseSlider from "../../../../components/cardsliders/CourseSlider";
import ApplyCourseSmall from "../../../../components/contact/ApplyCourseSmall";
import Hiringpartners from "../../../../components/Hiringpartners/Hiringpartners";
import { Card } from "react-bootstrap";
import { FaReact } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { SiPytorch } from "react-icons/si";
import { SiScikitlearn } from "react-icons/si";
import { SiKeras } from "react-icons/si";
import { SiPandas } from "react-icons/si";
import { SiJupyter } from "react-icons/si";
import { ImCss3 } from "react-icons/im";
import TechCard from "../../../../components/cards/TechCard";
import TestimonialCard from "../../../../components/cards/TestimonialCard";

const AIML = () => {
  return (
    <>
      <section id="course-top" className="aiml-course-top">
        <div className="course-top container">
          <div className="course-top-left">
            <h1>Artificial Intelligence & Machine Learning</h1>
            <h3>
              AI/ML course equips employees with practical skills for
              integrating AI/ML into software. It covers fundamental principles,
              Python implementation, data processing, model evaluation,
              integration, and ethics. Our aim is to enhance our company's tech
              capabilities.
            </h3>
            <div className="row">
              <a href="#apply" className="cta">
                Apply
              </a>
              <a href="assets/pdfs/web.pdf" className="cta">
                Download Syllabus
              </a>
            </div>
          </div>
          <div className="course-top-right">
            <CourseSlider
              src3={"assets/training/career.jpg"}
              title3={"Career Growth"}
              text3={
                " Full-stack developers have multi-dimensional career growth across companies."
              }
              src1={"assets/training/payment.jpg"}
              title1={"Highest paid"}
              text1={
                "The average annual earning potential for a full stack developer in India is ₹ 7,50,149 Which is growing faster."
              }
              src2={"assets/training/productivity.jpg"}
              title2={"Productivity"}
              text2={
                "The ability to see the big picture empowers full-stack developers to make decisions faster."
              }
            />
          </div>
        </div>
      </section>
      {/* Top End */}
      {/* Start */}
      <section id="top-mid">
        <div className="top-mid jcse">
          <div className="course-topCard column br3-orange">
            <h1>Next Batch starts</h1>
            <p>15 Feb, 2024</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Program Duration</h1>
            <p>3 months</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Online Bootcamp</h1>
            <p>Learning Format</p>
          </div>
          <div className="course-topCard column ">
            <h1>Fees</h1>
            <p>&#8377;11499</p>
          </div>
        </div>
      </section>
      {/* End */}

      <main className="main container">
        {/* Course Overview start */}
        <section className=" section ">
          <h2 className="heading2">Course Overview</h2>
          <div className="course-overview-container">
            <div className="course-overview-left">
              <p>
                AI/ML course provided by our software company focuses on
                equipping our employees with practical skills for integrating AI
                and ML technologies into software development. The course covers
                fundamental AI/ML principles, hands-on implementation using
                Python and popular frameworks, data processing, model
                evaluation, software integration, and ethical considerations.
                Our goal is to empower participants to develop and deploy
                AI-powered software solutions effectively, enhancing our
                company's technological capabilities.
              </p>
              <h2 className="heading2">Key Features</h2>
              <ul>
                <li>
                  <VscPass />
                  Comprehensive Curriculum
                </li>
                <li>
                  <VscPass />
                  Hands-on Projects
                </li>
                <li>
                  <VscPass />
                  Industry-Relevant Skills
                </li>
                <li>
                  <VscPass />
                  Experienced Instructors
                </li>
                <li>
                  <VscPass />
                  Access to Tools and Resources
                </li>
                <li>
                  <VscPass />
                  Networking Opportunities
                </li>
                <li>
                  <VscPass />
                  Flexibility
                </li>
                <li>
                  <VscPass />
                  Recognition and Credibility
                </li>
                <li>
                  <VscPass />
                  Career Support
                </li>
                <li>
                  <VscPass />
                  Updated Curriculum
                </li>
              </ul>
            </div>
            <div className="course-overview-right">
              <ApplyCourseSmall />
            </div>
          </div>
        </section>
        {/* Course Overview end */}
        {/* Certificate Section Start */}
        <section className=" certificate-section section container">
          <div className="content">
            <div className="content-left">
              <h5 className="heading1">AI & ML Certification Advantage</h5>
              <p>
                AI/ML course provides a comprehensive understanding of
                artificial intelligence and machine learning concepts,
                algorithms, and applications. It includes practical hands-on
                experience, covering topics such as data processing, model
                evaluation, and deployment. The course equips participants with
                the skills necessary to develop and implement AI/ML solutions
                effectively in various domains.
              </p>
              <ul>
                <li>
                  <b>Enhanced Career Prospects: </b>Certification can
                  significantly improve job prospects and career opportunities
                  in the rapidly growing field of AI and ML, making individuals
                  more competitive in the job market.
                </li>
                <li>
                  <b>Skill Validation: </b>Certification validates one's
                  proficiency and understanding of AI/ML concepts, algorithms,
                  and tools, demonstrating practical knowledge and competence in
                  the field.
                </li>
                <li>
                  <b>Practical Knowledge and Experience: </b>Through hands-on
                  projects and practical assignments, certification programs
                  provide valuable real-world experience that can be directly
                  applied in AI/ML roles and projects.
                </li>
                <li>
                  <b>Career Advancement: </b>AI/ML certification can open doors
                  to advanced job roles, higher responsibilities, and increased
                  earning potential, providing opportunities for professional
                  growth and development.
                </li>
                <li>
                  <b>Problem-Solving Abilities: </b>AI/ML certification programs
                  often emphasize critical thinking and problem-solving skills,
                  enabling individuals to tackle complex challenges and
                  contribute to innovative solutions within various industries.
                </li>
                <li>
                  <b>Access to Resources: </b>Certification programs often
                  provide access to valuable resources, such as tools, datasets,
                  and software, which can facilitate continued learning and
                  practical application of AI/ML techniques.
                </li>
              </ul>
            </div>

            <figure className="content-right">
              <img
                src="assets/training/mern/certificate.png"
                alt="Web Development Image"
              />
            </figure>
          </div>
        </section>
        {/* Certificate Section End */}
        {/* Curriculum Start */}
        <section>
          <h3 className="heading3">Curriculum</h3>
          <div className="course-AccordionComp">
            <div className="course-AccordionComp-left">
              <h3 className="heading3">Foundations</h3>
              <AccordionComp
                title={"Mathematics & Statistics Essentials"}
                titleWidth={"100%"}
                jdli1={"Introduction to Mathematics"}
                jdli2={"Coordinate Geometry"}
                jdli3={"Linear Algebra"}
                jdli4={"Eigenvalues, Eigenvectors, and Eigendecomposition"}
                jdli5={"Introduction to Calculus"}
                jdli6={"Understanding Data"}
                jdli7={"Descriptive Statistics"}
                jdli8={"Data Visualization"}
                jdli9={"Probability"}
                jdli10={"Probability Distributions"}
                jdli11={"Sampling and Sampling Techniques"}
                jdli12={"Inferential Statistics"}
                jdli13={"Application of Inferential Statistics"}
                jdli14={"Relationship Between Variables"}
                jdli15={"Application of Statistics in Business"}
              />
              <AccordionComp
                title={"Programming Refresher"}
                titleWidth={"100%"}
                jdli1={"Fundamentals of Programming"}
                jdli2={"Introduction to Python Programmings"}
                jdli3={"Python Data Types and Operators"}
                jdli4={"Conditional Statements and Loops in Python"}
                jdli5={"Python Functions"}
                jdli6={" Object-Oriented Programming Concepts with Python"}
                jdli7={"Threading"}
                jdhead2={"Python for Data Science"}
                jd2li1={"Python Basics"}
                jd2li2={"Python Data Structures"}
                jd2li3={"Python Programming Fundamentals"}
                jd2li4={"Working with Data in Python"}
                jd2li5={"Working with NumPy Arrays"}
              />
              <h3 className="heading3">Core</h3>
              <AccordionComp
                title={"Applied Data Science with Python"}
                titleWidth={"100%"}
                jdli1={"Introduction to Data Science"}
                jdli2={"Essentials of Python Programming"}
                jdli3={"NumPy"}
                jdli4={"Linear Algebra"}
                jdli5={"Statistics Fundamentals"}
                jdli6={"Advanced Statistics"}
                jdli7={"Working with Pandas"}
                jdli8={"Data Analysis"}
                jdli9={"Data Wrangling"}
                jdli10={"Data Visualization"}
                jdli11={"End-to-End Statistics Application in Python"}
              />
              <AccordionComp
                title={"Machine Learning"}
                titleWidth={"100%"}
                jdli1={"Machine Learning Fundamentals"}
                jdli2={"Supervised Learning"}
                jdli3={"Regression Models and Applications"}
                jdli4={"Classification Models and Applications"}
                jdli5={"Unsupervised Learning"}
                jdli6={"Ensemble Learning"}
                jdli7={"Recommendation Systems"}
              />
              <AccordionComp
                title={"Deep Learning with TensorFlow"}
                titleWidth={"100%"}
                jdli1={
                  " Introduction to Artificial Intelligence and Deep Learning"
                }
                jdli2={"Artificial Neural Network"}
                jdli3={"Deep Neural Network and Tools"}
                jdli4={
                  "Optimization, Tuning, and Interpretability of Deep Neural Networks"
                }
                jdli5={"Convolutional Neural Networks (CNN)"}
                jdli6={"Advanced Statistics"}
                jdli7={"Recurrent Neural Networks"}
                jdli8={"Autoencoders"}
              />
              <AccordionComp
                title={"Deep Learning Specialization"}
                titleWidth={"100%"}
                jdli1={"Introduction to Deep Learning"}
                jdli2={"Artificial Neural Network"}
                jdli3={"Deep Neural Network and Tools"}
                jdli4={"TensorFlow"}
                jdli5={"Model Optimization and Performance Improvement"}
                jdli6={"Convolutional Neural Networks (CNNs)"}
                jdli7={"Transfer Learning"}
                jdli8={"Object Detection"}
                jdli9={"Recurrent Neural Networks (RNNs)"}
                jdli10={
                  "Transformer Models for Natural Language Processing (NLP)"
                }
                jdli11={"Getting Started with Autoencoders"}
                jdli12={"PyTorch"}
              />
              <AccordionComp
                title={"Essentials of Generative AI, Prompt Engineering & ChatGPT"}
                titleWidth={"100%"}
                jdli1={"Generative AI and its Landscape"}
                jdli2={"Explainable AI"}
                jdli3={"Conversational AI"}
                jdli4={"Prompt Engineering"}
                jdli5={"Designing and Generating Effective Prompts"}
                jdli6={"Large Language Models"}
                jdli7={"ChatGPT and its Applications"}
                jdli8={"Fine-tuning ChatGPT"}
                jdli9={"Ethical Considerations in Generative AI Models"}
                jdli10={
                  "Responsible Data Usage and Privacy"
                }
                jdli11={"The Future of Generative AI"}
                jdli12={"AI Technologies for Innovation"}
              />
              <h3 className="heading3">Version Control</h3>
              <AccordionComp
                title={"Git & GitHub"}
                titleWidth={"100%"}
                jdhead={"Purpose of Git & GitHub"}
                jdli1={"Overview of version control"}
                jdli2={"Importance in software development"}
                jdli3={"Installing Git"}
                jdli4={"Initializing a Git repository"}
                jdli5={"Basic commands: add, commit, status"}
                jdli6={"Understanding branches"}
                jdli7={"Creating and switching branches"}
                jdli8={"Cloning remote repositories"}
                jdli9={"Pushing and pulling changes"}
                jdli10={"Basics of pull requests"}
                jdli11={"Creating a GitHub account"}
                jdli12={"Basics of GitHub repositories and issues"}
                jdli13={"Pushing changes to GitHub"}
              />
            </div>
            <div className="course-AccordionComp-right">
              <Card key={"Secondary"} className="mb-2 simplecard">
                <Card.Header
                  style={{ backgroundColor: "#00e1ff" }}
                  className="simplecard-title"
                >
                  Program Highlights
                </Card.Header>
                <Card.Body className="simplecard-desc">
                  {/* <Card.Title>Title</Card.Title> */}
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid blue" }}
                    >
                      <VscPass style={{ color: "blue" }} />
                    </div>
                    <div className="text">Live Session across 3 Months</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid green" }}
                    >
                      <VscPass style={{ color: "green" }} />
                    </div>
                    <div className="text">100% Job Ready</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid orange" }}
                    >
                      <VscPass style={{ color: "orange" }} />
                    </div>
                    <div className="text">
                      Industry Capstone Projects & Case studies
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid purple" }}
                    >
                      <VscPass style={{ color: "purple" }} />
                    </div>
                    <div className="text">24*7 Support</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
        {/* Curriculum end */}
        {/* Start Technology we use Section  */}
        <h1 className="heading1">Skills Covered</h1>
        <ul className="skills-covered row flex-wrap">
          <li>Data Preprocessing</li>
          <li>Supervised Learning</li>
          <li>Unsupervised Learning</li>
          <li>Deep Learning</li>
          <li>Natural Language Processing</li>
          <li>Model Evaluation</li>
          <li>Model Deployment</li>
        </ul>
        {/* End Technology we use Section  */}
        {/* Start Technology we use Section  */}
        <section className="technologies-weuse-container section ">
          <h1 className="heading1">Tools Covered</h1>
          <div className="row flex-wrap">
            <TechCard
              heading={"Python"}
              icon={<FaPython style={{ color: "#4B8BBE" }} />}
            />
            <TechCard
              heading={"PyTorch"}
              icon={<SiPytorch style={{ color: "#F14E32" }} />}
            />
            <TechCard
              heading={"scikit"}
              icon={<SiScikitlearn style={{ color: "#4B8BBE" }} />}
            />
            <TechCard
              heading={"Keras"}
              icon={<SiKeras style={{ color: "red" }} />}
            />
            <TechCard
              heading={"Pandas"}
              icon={<SiPandas style={{ color: "#172A39" }} />}
            />
            <TechCard
              heading={"Jupyter Notebooks"}
              icon={<SiJupyter style={{ color: "#FF5733" }} />}
            />
            <TechCard
              heading={"NumPy"}
              icon={<ImCss3 style={{ color: "#2965f1" }} />}
            />
            <TechCard
              heading={"Matplotlib"}
              icon={<FaReact style={{ color: "#61DBFB" }} />}
            />
          </div>
        </section>
        {/* End Technology we use Section  */}
        <Hiringpartners/>
        <section id="apply" className="section apply-course">
          <div className="home-services-header">
            <h1 className="section-title">
              <span>Apply</span> Here
            </h1>
          </div>
          <ApplyCourse />
        </section>
      </main>
    </>
  );
};

export default AIML;
