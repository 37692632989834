import React from 'react'

const Hiringpartners = () => {
  return (
    <>
       <section className="testimonial-section section ">
          <h2 className="heading2">Our Hiring Partners</h2>
          <div className="row2">
            <div>
              <img src="assets/hiringpartner/one.jpg" alt="hiringpartner" />
            </div>
            <div>
              <img src="assets/hiringpartner/two.jpg" alt="hiringpartner" />
            </div>
            <div>
              <img src="assets/hiringpartner/three.jpg" alt="hiringpartner" />
            </div>
            <div>
              <img src="assets/hiringpartner/four.jpg" alt="hiringpartner" />
            </div>
            <div>
              <img src="assets/hiringpartner/five.jpg" alt="hiringpartner" />
            </div>
            <div>
              <img src="assets/hiringpartner/six.jpg" alt="hiringpartner" />
            </div>
            <div>
              <img src="assets/hiringpartner/seven.jpg" alt="hiringpartner" />
            </div>
            <div>
              <img src="assets/hiringpartner/nine.jpg" alt="hiringpartner" />
            </div>
            <div>
              <img src="assets/hiringpartner/ten.jpg" alt="hiringpartner" />
            </div>
          </div>
          </section>
          </>
  )
}

export default Hiringpartners;
