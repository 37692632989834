import React from 'react'
import './TextComponent.css'
const TextComponent = ({para1,para2,para3,para4,para5,para6,para7}) => {
  return (
    <>
    <section className="text-component-container section container">
          <div className="content">
            <p>
              {para1}
            </p>
            <p>
              {para2}
            </p>
            <p>
              {para3}
            </p>
            <p>
              {para4}
            </p>
            <p>
              {para5}
            </p>
            <p>
              {para6}
            </p>
            <p>
              {para7}
            </p>
          </div>
        </section>
    </>
  )
}

export default TextComponent