import React from 'react';
import './Contact.css'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import axios from "axios";
import { useState } from "react";
import AlertComp from "../Alert/Alert";


  
const Contact = () => {

	const [name, setName] = useState("");
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");

  async function submit(e) {
    e.preventDefault();

    try {
      await axios.post("https://icoesolutionserver.onrender.com/api/contact-email", { name, email,phone,message })
      .then(resp=>{
		setResponse(resp.data)
		setName("")
        setPhone("")
        setEmail("")
        setMessage("")
	  })
      .catch((e)=>{
        alert('Something went wrong')
        console.log(e)
      })
    } 
    catch (e) {
        console.log(e)
    }
  }
  return (
    <>
    
 

<section class="contact">
		<div class="content">
			<h2>Contact Us</h2>
			<p>Talk to us for your business needs, all staffing requirements and anything on technology. We would love to here from you & share our expertise with you.</p>
		</div>
		<div class="contact-page-container">
			<div class="contactInfo">
				<div class="box">
					<div class="icon"><FaLocationDot /></div>
					<div class="text">
						<h3>Address</h3>
						<p>H.N.249, Peptech Town<br />Chhatarpur(M.P.), India<br/>471001</p>
					</div>
				</div>
				<div class="box">
					<div class="icon"><FaPhoneAlt /></div>
					<div class="text">
						<h3>Phone</h3>
						<p>+91 9981810146</p>
					</div>
				</div>
				<div class="box">
					<div class="icon"><IoMdMail /></div>
					<div class="text">
						<h3>Email</h3>
						<p>info@icoesolution.com</p>
					</div>
				</div>
				<h2 class="txt">Connect with us</h2>
			</div>

			<div class="contactForm">

				<form action='POST' onSubmit={submit}>
					<h2>Send Message</h2>
					<div class="inputBox">
						<input type="text" name="" required="true" onChange={(e)=>{setName(e.target.value)}} value={name}/>
						<span>Full Name</span>
					</div>
					<div class="inputBox">
						<input type="number" name="" required="true" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
						<span>Phone</span>
					</div>
					<div class="inputBox">
						<input type="email" name="" required="true" onChange={(e)=>{setEmail(e.target.value)}} value={email}/>
						<span>Email</span>
					</div>
					<div class="inputBox">
						<textarea  required="true" onChange={(e)=>{setMessage(e.target.value)}} value={message}></textarea>
						<span>Type your Message...</span>
					</div>
					{response && (<AlertComp message={response}/>)}

					<div class="inputBox">
						<input type="submit" value="Send" />
					</div>
				</form>
			</div>
		</div>
	</section>
    </>
  )
}

export default Contact;