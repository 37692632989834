import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Portfolio from "./pages/Portfolio/Portfolio";
import Career from "./pages/Career/Career";
import TechnicalTraining from "./pages/Services/TechnicalTraining/TechnicalTraining";
import Mern from "./pages/Services/TechnicalTraining/Courses/Mern";
import WebDevelopment from "./pages/Services/WebDevelopment/WebDevelopment";
import Footer from "./components/Footer/Footer";
import ITStaffing from "./pages/Services/ITStaffing/ITStaffing";
import Contact from "./components/contact/Contact";
import MobileDevelopment from "./pages/Services/MobileDevelopment/MobileDevelopment";
import EducationalSupport from "./pages/Services/EducationalSupport/EducationalSupport";
import SwDevOutsourcing from "./pages/Services/SwDevOutsourcing/SwDevOutsourcing";
import JavaFull from "./pages/Services/TechnicalTraining/Courses/JavaFull";
import AIML from "./pages/Services/TechnicalTraining/Courses/AIML";
import DataScience from "./pages/Services/TechnicalTraining/Courses/DataScience";
import Cloud from "./pages/Services/TechnicalTraining/Courses/Cloud";
import DevOps from "./pages/Services/TechnicalTraining/Courses/DevOps";
import Register from "./pages/registerLogin/Register";
import ScrollToTop from "./ScrollToTopOnPageChange";
import PrivacyPolicy from "./pages/Privacy/PrivacyPolicy";
import TermsCondition from "./pages/Privacy/TermsCondition";

function App() {
  return (
    <>
      <BrowserRouter>
          <ScrollToTop/>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/career" element={<Career />} />
          <Route path="/technicalTraining" element={<TechnicalTraining />} />
          <Route path="/mern" element={<Mern />} />
          <Route path="/javafull" element={<JavaFull />} />
          <Route path="/aiml" element={<AIML />} />
          <Route path="/datascience" element={<DataScience />} />
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/devops" element={<DevOps />} />
          <Route path="/SwDevOutsourcing" element={<SwDevOutsourcing />} />
          <Route path="/webd" element={<WebDevelopment />} />
          <Route path="/mobiled" element={<MobileDevelopment />} />
          <Route path="/itstaffing" element={<ITStaffing />} />
          <Route path="/EducationSupport" element={<EducationalSupport />} />
          <Route path="/about" element={<About />} />
          <Route path="/register" element={<Register />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsCondition />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
