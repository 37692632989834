import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CardSlider.css";
import AlumniCard from "../cards/AlumniCard";

const AlumniSlider = ({ heading }) => {
  // if(window.innerWidth<='1200' && window.innerWidth>='800'){
  //   setSlides(2)
  // }
  // else if(window.innerWidth<='800'){
  //   setSlides(1)
  // }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 592,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings} className="card-slider">
        <div className="slider-item px-1">
          <AlumniCard
            src={"assets/training/alumni/Kashish Shukla.jpg"}
            name={"Kashish Shukla"}
            designation={"Trainee in Full Stack Web Development"}
            heading={"Diverse learning, practical experience,and valuable mentorship enhanced my skils."}
            text={
              "Engaging learning experience, passionate instructors, and a good balance of theory and practice. A must for aspiring developers, providing valuable skills for real-world challenges. Highly recommended."
            }
          />
        </div>
        <div className="slider-item px-1">
          <AlumniCard
            src={"assets/training/alumni/Satyam Tiwari.jpg"}
            name={"Satyam Tiwari"}
            designation={"Trainee in Full Stack Web Development"}
            heading={"Demonstrates aptitude; grasp concepts, apply skills, and adapt effectively."}
            text={
              "Engaging in full stack web development training has been insightful. learning front-end and back-end technologies broadened my skills. grateful for practical experience and mentorship provided. excited for more challenges ahead."
            }
          />
        </div>

        <div className="slider-item px-1">
          <AlumniCard
            src={"assets/training/alumni/Aashi Khare.jpeg"}
            name={"Aashi Khare"}
            designation={"Trainee in Full Stack Development"}
            heading={"Solid progress in full-stack traning; continue refining skills diligently."}
            text={
              "Invaluable full-stack web development experience with knowledgeable instructors, well-organized course, and hands-on labs. This transformative journey prepared me thoroughly. Highly recommend for a game-changing mix of theory and practical skills."
            }
          />
        </div>
        <div className="slider-item px-1">
          <AlumniCard
            src={"assets/training/alumni/Akhilesh Jaisawal.jpg"}
            name={"Akhilesh Jaisawal"}
            designation={"Trainee in Front-end Developer"}
            heading={"Invaluable skils acquired, diverse training, greatful for mentorship received."}
            text={
              "Fantastic course structure, perfect blend of lectures and projects. An enjoyable learning experience left me well-prepared for the dynamic world of professional web development."
            }
          />
        </div>
        <div className="slider-item px-1">
          <AlumniCard
            src={"assets/training/alumni/Amit Kankane.jpeg"}
            name={"Amit Kankane"}
            designation={"Trainee in Backend developer"}
            heading={"Impressive strides, adept problem-solving, and embraces challenges with enthusiasm."}
            text={
              "This course was a game-changer! The hands-on approach bridged theory and application seamlessly. Instructors were experts, making complex topics accessible. Highly recommended for aspiring developers."
            }
          />
        </div>
        <div className="slider-item px-1">
          <AlumniCard
            src={"assets/training/alumni/Chandra Prabha Chaurasiya.jpeg"}
            name={"Chandra Prabha Chaurasiya"}
            designation={"Trainee in Python"}
            heading={"I got this opportunity where I learnt a lot from basics (zero knowledge)"}
            text={
              "This course was a game-changer! The hands-on approach bridged theory and application seamlessly. Instructors were experts, making complex topics accessible. Highly recommended for aspiring developers."
            }
          />
        </div>
        <div className="slider-item px-1">
          <AlumniCard
            src={"assets/training/alumni/Kapil Tiwari.jpg"}
            name={"Kapil Tiwari"}
            designation={"Trainee in Full Stack Web Development"}
            heading={"Showcased commendable growth; grasp concepts swiftly, refine coding percision persistently."}
            text={
              "Exceeded expectations. Real-world projects provided practical readiness for professional web development. The course delivered valuable skills, leaving me well-prepared for the workforce."
            }
          />
        </div>

        <div className="slider-item px-1">
          <AlumniCard
            src={"assets/training/alumni/Rekha Shivhare.jpg"}
            name={"Rekha Shivhare"}
            designation={"Trainee in Full Stack Web Development / C++"}
            heading={"Dynamic traning, hands-on skils, appreciative of supportive mentorship"}
            text={
              "A wise decision indeed. Rich content, real-world projects, and an enjoyable learning journey. Well-equipped for practical challenges in full-stack development. Highly recommended for those seeking a comprehensive learning experience."
            }
          />
        </div>

        <div className="slider-item px-1">
          <AlumniCard
            src={"assets/training/alumni/Virender Kumar.jpg"}
            name={"Virender Kumar"}
            designation={"Trainee in Full Stack Web Development"}
            heading={"Quick learner, adeptly applies skills, displays commendable problem-solving in tasks."}
            text={
              "A transformative experience with a well-structured curriculum, patient instructors, and practical insights. Confidence gained for tackling full-stack development projects. Highly recommended for those seeking a comprehensive and practical approach to learning."
            }
          />
        </div>
      </Slider>
    </>
  );
};

export default AlumniSlider;
