/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Course.css";
import { VscPass } from "react-icons/vsc";
import AccordionComp from "../../../../components/AccordionComp/AccordionComp";
import ApplyCourse from "../../../../components/contact/ApplyCourse";
import CourseSlider from "../../../../components/cardsliders/CourseSlider";
import ApplyCourseSmall from "../../../../components/contact/ApplyCourseSmall";
import Hiringpartners from "../../../../components/Hiringpartners/Hiringpartners";
import { Card } from "react-bootstrap";
import { FaReact } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { SiPytorch } from "react-icons/si";
import { SiScikitlearn } from "react-icons/si";
import { SiKeras } from "react-icons/si";
import { SiPandas } from "react-icons/si";
import { SiJupyter } from "react-icons/si";
import { ImCss3 } from "react-icons/im";
import TechCard from "../../../../components/cards/TechCard";
import TestimonialCard from "../../../../components/cards/TestimonialCard";

const DataScience = () => {
  return (
    <>
      <section id="course-top" className="aiml-course-top">
        <div className="course-top container">
          <div className="course-top-left">
            <h1>Data Science</h1>
            <h3>
              A Data Science course teaches skills in programming (e.g.,
              Python), statistical analysis, machine learning, and data
              visualization. It often includes a hands-on project for real-world
              application and covers ethical considerations. The goal is to
              equip individuals to analyze data effectively and make informed
              decisions.
            </h3>
            <div className="row">
              <a href="#apply" className="cta">
                Apply
              </a>
              <a href="assets/pdfs/web.pdf" className="cta">
                Download Syllabus
              </a>
            </div>
          </div>
          <div className="course-top-right">
            <CourseSlider
              src3={"assets/training/career.jpg"}
              title3={"Career Growth"}
              text3={
                " Full-stack developers have multi-dimensional career growth across companies."
              }
              src1={"assets/training/payment.jpg"}
              title1={"Highest paid"}
              text1={
                "The average annual earning potential for a full stack developer in India is ₹ 7,50,149 Which is growing faster."
              }
              src2={"assets/training/productivity.jpg"}
              title2={"Productivity"}
              text2={
                "The ability to see the big picture empowers full-stack developers to make decisions faster."
              }
            />
          </div>
        </div>
      </section>
      {/* Top End */}
      {/* Start */}
      <section id="top-mid">
        <div className="top-mid jcse">
          <div className="course-topCard column br3-orange">
            <h1>Next Batch starts</h1>
            <p>20 Feb, 2024</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Program Duration</h1>
            <p>2 months</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Online Bootcamp</h1>
            <p>Learning Format</p>
          </div>
          <div className="course-topCard column ">
            <h1>Fees</h1>
            <p>&#8377;6999</p>
          </div>
        </div>
      </section>
      {/* End */}

      <main className="main container">
        {/* Course Overview start */}
        <section className=" section ">
          <h2 className="heading2">Course Overview</h2>
          <div className="course-overview-container">
            <div className="course-overview-left">
              <p>
                A Data Science course is an educational program designed to
                teach individuals the skills and knowledge necessary for working
                with data, extracting valuable insights, and making informed
                decisions. Data science courses typically cover a range of
                topics, including programming languages like Python or R,
                statistical analysis, machine learning, data visualization, and
                practical application of these skills to real-world scenarios.
              </p>
              <h2 className="heading2">Key Features</h2>
              <ul>
                <li>
                  <VscPass />
                  Comprehensive Curriculum
                </li>
                <li>
                  <VscPass />
                  Hands-on Projects
                </li>
                <li>
                  <VscPass />
                  Industry-Relevant Skills
                </li>
                <li>
                  <VscPass />
                  Experienced Instructors
                </li>
                <li>
                  <VscPass />
                  Data Manipulation and Analysis
                </li>
                <li>
                  <VscPass />
                  Networking Opportunities
                </li>
                <li>
                  <VscPass />
                  Real-world Projects
                </li>
                <li>
                  <VscPass />
                  Machine Learning Fundamentals
                </li>
                <li>
                  <VscPass />
                  Career Support
                </li>
                <li>
                  <VscPass />
                  Updated Curriculum
                </li>
              </ul>
            </div>
            <div className="course-overview-right">
              <ApplyCourseSmall />
            </div>
          </div>
        </section>
        {/* Course Overview end */}
        {/* Certificate Section Start */}
        <section className=" certificate-section section container">
          <div className="content">
            <div className="content-left">
              <h5 className="heading1">Data Science Certification Advantage</h5>
              <p>
              Earning a Data Science course certification offers numerous advantages for individuals looking to enter or advance in the field. Here are some key benefits:
              </p>
              <ul>
               <li><b>Credibility and Validation:</b>Certification validates your skills and knowledge in data science, providing credibility to potential employers.</li>
               <li><b>Enhanced Employability:</b>Certified individuals are often more attractive to employers, increasing job opportunities and career options.</li>
               <li><b>Industry Recognition:</b>Certifications from reputable organizations are widely recognized within the data science and tech industry.</li>
               <li><b>Competitive Edge:</b>Having a certification gives you a competitive advantage over non-certified candidates in job applications.</li>
               <li><b>Skill Specialization:</b>Certifications often focus on specific skills or tools, allowing you to specialize in a particular area of data science.</li>
               <li><b>Career Advancement:</b>Certification can contribute to promotions and salary increases, aiding in career progression.</li>
               <li><b>Standardized Skill Assessment:</b>Certification provides a standardized way for employers to assess your proficiency in data science.</li>
               <li><b>Professional Development:</b>Certification contributes to your overall professional development, demonstrating a commitment to excellence.</li>
               <li><b>Increased Confidence:</b>Completing a certification program boosts your confidence in your data science skills and abilities.</li>
               <li><b>Specialized Roles:</b>Certifications can qualify you for specialized roles within the data science domain, such as machine learning engineer or data engineer.</li>
              </ul>
            </div>

            <figure className="content-right">
              <img
                src="assets/training/mern/certificate.png"
                alt="Web Development Image"
              />
            </figure>
          </div>
        </section>
        {/* Certificate Section End */}
        {/* Curriculum Start */}
        <section>
          <h3 className="heading3">Curriculum</h3>
          <div className="course-AccordionComp">
            <div className="course-AccordionComp-left">
            <AccordionComp
                title={"Programming Refresher"}
                titleWidth={"100%"}
                jdli1={"Fundamentals of Programming"}
                jdli2={"Introduction to Python Programmings"}
                jdli3={"Python Data Types and Operators"}
                jdli4={"Conditional Statements and Loops in Python"}
                jdli5={"Python Functions"}
                jdli6={" Object-Oriented Programming Concepts with Python"}
                jdli7={"Threading"}
                jdhead2={"Python for Data Science"}
                jd2li1={"Python Basics"}
                jd2li2={"Python Data Structures"}
                jd2li3={"Python Programming Fundamentals"}
                jd2li4={"Working with Data in Python"}
                jd2li5={"Working with NumPy Arrays"}
              />
              <AccordionComp
                title={"Statistics Essentials For Data Science"}
                titleWidth={"100%"}
                jdli1={"Introduction to Statistics "}
                jdli2={"Understanding the Data "}
                jdli3={"Descriptive Statistics"}
                jdli4={"Data Visualization"}
                jdli5={"Probability"}
                jdli6={"Probability Distributions"}
                jdli7={"Sampling and Sampling Techniques "}
                jdli8={"Inferential Statistics"}
                jdli9={"Application of Inferential Statistics"}
                jdli10={"Relation between Variables"}
                jdli11={"Application of Statistics in Business"}
              />
              <AccordionComp
                title={"Business Analytics with Excel"}
                titleWidth={"100%"}
                jdli1={"Introduction to Business Analytics"}
                jdli2={"Formatting, Conditional Formatting, and Important Functions"}
                jdli3={"Analyzing Data with Pivot Tables"}
                jdli4={"Dashboarding"}
                jdli5={"Business Analytics with Excel"}
                jdli6={"Data Analysis Using Statistics"}
                jdli7={"Power BI"}
              />

              <AccordionComp
                title={"SQL"}
                titleWidth={"100%"}
                jdli1={"Introduction"}
                jdli2={"Introduction to SQL"}
                jdli3={"Database Normalization and Entity Relationship (ER) Model"}
                jdli4={"Installation and SetUp"}
                jdli5={"Working with Databases and Tables"}
                jdli6={"Working with Operators, Constraints, and Data Types"}
                jdli7={"Functions in SQL"}
                jdli8={"Subqueries, Operators, and Derived Tables in SQL"}
                jdli9={"Windows Functions in SQL"}
                jdli10={"Working with Views"}
                jdli11={"Stored Procedures and Triggers in SQL"}
                jdli12={"Performance Optimization and Best Practices in SQL"}
              />
              <AccordionComp
                title={"Data Science with Python"}
                titleWidth={"100%"}
                jdli1={"Data Science Overview"}
                jdli2={" Data Analytics Overview"}
                jdli3={"Statistical Analysis and Business Applications"}
                jdli4={"Python Environment Setup and Essentials "}
                jdli5={"Mathematical Computing with Python (NumPy)"}
                jdli6={"Scientific Computing with Python (SciPy)"}
                jdli7={"Data Manipulation with Pandas"}
                jdli8={"Machine Learning with Scikit-learn"}
                jdli9={"Natural Language Processing with Scikit-learn"}
                jdli10={"Data Visualization in Python using Matplotlib."}
                jdli11={"Web Scraping with Beautiful Soup"}
                jdli12={"Python Integration with Hadoop, MapReduce, and Spark"}
              />

              
            
            </div>
            <div className="course-AccordionComp-right">
              <Card key={"Secondary"} className="mb-2 simplecard">
                <Card.Header
                  style={{ backgroundColor: "#00e1ff" }}
                  className="simplecard-title"
                >
                  Program Highlights
                </Card.Header>
                <Card.Body className="simplecard-desc">
                  {/* <Card.Title>Title</Card.Title> */}
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid blue" }}
                    >
                      <VscPass style={{ color: "blue" }} />
                    </div>
                    <div className="text">Live Session across 2 Months</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid green" }}
                    >
                      <VscPass style={{ color: "green" }} />
                    </div>
                    <div className="text">100% Job Ready</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid orange" }}
                    >
                      <VscPass style={{ color: "orange" }} />
                    </div>
                    <div className="text">
                      Industry Capstone Projects & Case studies
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid purple" }}
                    >
                      <VscPass style={{ color: "purple" }} />
                    </div>
                    <div className="text">24*7 Support</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
        {/* Curriculum end */}
        {/* Start Technology we use Section  */}
        <h1 className="heading1">Skills Covered</h1>
        <ul className="skills-covered row flex-wrap">
          <li>Data Preprocessing</li>
          <li>Supervised Learning</li>
          <li>Unsupervised Learning</li>
          <li>Deep Learning</li>
          <li>Natural Language Processing</li>
          <li>Model Evaluation</li>
          <li>Model Deployment</li>
        </ul>
        {/* End Technology we use Section  */}
        {/* Start Technology we use Section  */}
        <section className="technologies-weuse-container section ">
          <h1 className="heading1">Tools Covered</h1>
          <div className="row flex-wrap">
            <TechCard
              heading={"Python"}
              icon={<FaPython style={{ color: "#4B8BBE" }} />}
            />
            <TechCard
              heading={"PyTorch"}
              icon={<SiPytorch style={{ color: "#F14E32" }} />}
            />
            <TechCard
              heading={"scikit"}
              icon={<SiScikitlearn style={{ color: "#4B8BBE" }} />}
            />
            <TechCard
              heading={"Keras"}
              icon={<SiKeras style={{ color: "red" }} />}
            />
            <TechCard
              heading={"Pandas"}
              icon={<SiPandas style={{ color: "#172A39" }} />}
            />
            <TechCard
              heading={"Jupyter Notebooks"}
              icon={<SiJupyter style={{ color: "#FF5733" }} />}
            />
            <TechCard
              heading={"NumPy"}
              icon={<ImCss3 style={{ color: "#2965f1" }} />}
            />
            <TechCard
              heading={"Matplotlib"}
              icon={<FaReact style={{ color: "#61DBFB" }} />}
            />
          </div>
        </section>
        {/* End Technology we use Section  */}
       <Hiringpartners/>
        <section id="apply" className="section apply-course">
          <div className="home-services-header">
            <h1 className="section-title">
              <span>Apply</span> Here
            </h1>
          </div>
          <ApplyCourse />
        </section>
      </main>
    </>
  );
};

export default DataScience;
