import React from 'react'

const CourseCard = ({src,alt,title,text}) => {
  return (
    <>
                  <div className="card3">
                    <div className="card3-image-container">
                      <img src={src} alt={alt} />
                    </div>
                    <div className="card3-content">
                      <h3>{title}</h3>
                      <p>
                        {text}
                      </p>
                      {/* <a href='#apply' className={'cta'} to={'/contact'}>Apply</a> */}
                    </div>
                  </div>
                </>
  )
}

export default CourseCard;