import React from "react";
import './Cards.css'

const AlumniCard = ({src,name,designation,heading,text}) => {
  return (
    <>
       <div class="alumniCard div4 eachdiv">
        <div class="userdetails">
          <div class="imgbox">
            <img
              src={src}
              alt="Alumni Image"
            />
          </div>
          <div class="detbox">
            <p class="name">{name}</p>
            <p class="designation">{designation}</p>
          </div>
        </div>
        <div class="review">
          <h4>{heading}</h4>
          <p>
            {text}
          </p>
        </div>
      </div>
    </>
  );
};

export default AlumniCard;
