/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Course.css";
import { VscPass } from "react-icons/vsc";
import AccordionComp from "../../../../components/AccordionComp/AccordionComp";
import ApplyCourse from "../../../../components/contact/ApplyCourse";
import CourseSlider from "../../../../components/cardsliders/CourseSlider";
import ApplyCourseSmall from "../../../../components/contact/ApplyCourseSmall";
import Hiringpartners from "../../../../components/Hiringpartners/Hiringpartners";
import { Card } from "react-bootstrap";
import { FaReact } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { BsGit } from "react-icons/bs";
import { DiJavascript1 } from "react-icons/di";
import { ImHtmlFive } from "react-icons/im";
import { ImCss3 } from "react-icons/im";
import TechCard from "../../../../components/cards/TechCard";

const Mern = () => {
  return (
    <>
      <section id="course-top" className="mern-course-top">
        <div className="course-top container">
          <div className="course-top-left">
            <h1>Full Stack Web Development</h1>
            <h3>
              This Full Stack Developer course is designed to introduce you to
              front-end, middleware, and back-end web developement technologies.
              You will learn to build an end-to-end application, test and deploy
              code, store data using MongoDB, and much more.
            </h3>
            <div className="row">
              <a href="#apply" className="cta">
                Apply
              </a>
              <a href="assets/pdfs/web.pdf" className="cta">
                Download Syllabus
              </a>
            </div>
          </div>
          <div className="course-top-right">
            <CourseSlider
              src1={"assets/training/career.jpg"}
              title1={"Career Growth"}
              text1={
                " Full-stack developers have multi-dimensional career growth across companies."
              }
              src2={"assets/training/payment.jpg"}
              title2={"Highest paid"}
              text2={
                "The average annual earning potential for a full stack developer in India is ₹ 7,50,149 Which is growing faster."
              }
              src3={"assets/training/productivity.jpg"}
              title3={"Productivity"}
              text3={
                "The ability to see the big picture empowers full-stack developers to make decisions faster."
              }
            />
          </div>
        </div>
      </section>
      {/* Top End */}
      {/* Start */}
      <section id="course-top-mid">
        <div className=" top-mid">
          <div className="course-topCard column br3-orange">
            <h1>Next Batch starts</h1>
            <p>09 Feb, 2024</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Program Duration</h1>
            <p>5 months</p>
          </div>
          <div className="course-topCard column br3-orange">
            <h1>Online Bootcamp</h1>
            <p>Learning Format</p>
          </div>
          <div className="course-topCard column ">
            <p><b>Fees: </b>&#8377;11499/-</p>
            <p><b>Monthly: </b>&#8377;2399/-</p>
          </div>
        </div>
      </section>
      {/* End */}

      <main className="main container">
        {/* Course Overview start */}
        <section className=" section ">
          <h2 className="heading2">Course Overview</h2>
          <div className="course-overview-container">
            <div className="course-overview-left">
              <p>
                Full stack web development refers to the practice of creating
                complete web applications, encompassing both the front end and
                back end components. It involves working with various
                technologies such as HTML, CSS, and JavaScript for the
                client-side (front end), and databases, servers, and application
                programming interfaces (APIs) for the server-side (back end).
                Full stack developers are skilled in both front end and back end
                development, allowing them to handle all aspects of creating a
                functional and interactive web application. They are proficient
                in using frameworks and libraries to streamline development
                processes, ensuring the seamless integration of different
                components to deliver a robust, user-friendly, and dynamic web
                experience.
              </p>
              <h2 className="heading2">Key Features</h2>
              <ul>
                <li>
                  <VscPass />
                  Masterclasses delivered by Experienced Professionals
                </li>
                <li>
                  <VscPass />
                  One-on-One with Industry Mentors
                </li>
                <li>
                  <VscPass />
                  Extra Doubt Clearing Session on Weekdays
                </li>
                <li>
                  <VscPass />
                  Technical Career Mentor
                </li>
                <li>
                  <VscPass />
                  Certification by Icoess Solutions
                </li>
                <li>
                  <VscPass />
                  24*7 Support
                </li>
                <li>
                  <VscPass />
                  E-learning videos on Full Stack Development
                </li>
                <li>
                  <VscPass />
                  Dedicated Learning Management Team
                </li>
              </ul>
            </div>
            <div className="course-overview-right">
              <ApplyCourseSmall />
            </div>
          </div>
        </section>
        {/* Course Overview end */}
        {/* Certificate Section Start */}
        <section className=" certificate-section section container">
          <div className="content">
            <div className="content-left">
              <h5 className="heading1">
                Full Stack Developer Certification Advantage
              </h5>
              <p>
                This comprehensive curriculum covers more than 15 software
                development tools and technologies to make your profile stand
                out as a MERN stack developer.
              </p>
              <ul>
                <li>
                  Learn core computer science concepts from leading industry
                  experts with content structured to ensure industry relevance
                </li>
                <li>
                  Build an end-to-end application with exciting features and
                  test it
                </li>
                <li>
                  Earn an industry-recognized course completion certificate
                </li>
                <li>Official study material</li>
                <li>Industry-recognized certification</li>
                <li>Real-time projects and exercises</li>
              </ul>
            </div>

            <figure className="content-right">
              <img
                src="assets/training/mern/certificate.png"
                alt="Web Development Image"
              />
            </figure>
          </div>
        </section>
        {/* Certificate Section End */}
        {/* Curriculum Start */}
        <section>
          <h3 className="heading3">Curriculum</h3>
          <div className="course-AccordionComp">
            <div className="course-AccordionComp-left">
              <h3 className="heading3">Frontend</h3>
              <AccordionComp
                title={"HTML"}
                titleWidth={"100%"}
                jdhead={"Purpose of HTML"}
                jdli1={"Structuring web pages."}
                jdli2={"Navigating the internet."}
                jdli3={"Embedding images and videos."}
                jdli4={
                  "Improving client-side data storage and offline capabilities."
                }
                jdli5={"Game development."}
                jdli6={"Interacting with native APIs."}
              />
              <AccordionComp
                title={"CSS"}
                titleWidth={"100%"}
                jdhead={"Purpose of CSS"}
                jdli1={"Defining the styles for web pages."}
                jdli2={"Saves a lot of time."}
                jdli3={"Pages load faster."}
                jdli4={"Easier Website maintenance."}
                jdli5={"Multiple device compatibility."}
              />
              <AccordionComp
                title={"JavaScript"}
                titleWidth={"100%"}
                jdhead={"Purpose of JavaScript"}
                jdli1={
                  "It can be used both in the front-end and back-end of web development."
                }
                jdli2={
                  "Serves everyone from beginners to intermediate and even advanced developers."
                }
                jdli3={
                  "Should be able to interact with Client and get their updates done. Strong written and verbal communication skills in English"
                }
                jdli4={"Transformed web browsers into application platforms."}
                jdli5={
                  "Wide range of frameworks and libraries to help build complex applications."
                }
              />
              <AccordionComp
                title={"ReactJS"}
                titleWidth={"100%"}
                jdhead={"Purpose of ReactJS"}
                jdli1={"React is a Library of Javascript."}
                jdli2={"Large-scale applications."}
                jdli3={"High-performance applications."}
                jdli4={
                  "React excels at handling complex user interfaces with ease."
                }
                jdli5={
                  "React can be used to build web, mobile, and desktop applications."
                }
              />
              <h3 className="heading3">Backend</h3>
              <AccordionComp
                title={"NodeJS"}
                titleWidth={"100%"}
                jdhead={"Purpose of NodeJS"}
                jdli1={"Node.js uses JavaScript on the server."}
                jdli2={
                  "It can create, open, read, write, delete, and close files on the server."
                }
                jdli3={"It can collect form data."}
                jdli4={"It can add, delete, modify data in your database."}
                jdli5={"It can generate dynamic page content."}
              />
              <AccordionComp
                title={"ExpressJS"}
                titleWidth={"100%"}
                jdhead={"Purpose of ExpressJS"}
                jdli1={
                  "Express was created to make APIs and web applications with ease."
                }
                jdli2={"It saves a lot of coding time almost by half."}
                jdli3={
                  "Another reason for using express is that it is written in javascript."
                }
                jdli4={
                  "Express lets so many new developers enter the field of web development."
                }
                jdli5={
                  "Express is fast to link it with databases like MySQL, MongoDB, etc."
                }
              />
              <AccordionComp
                title={"MongoDB"}
                titleWidth={"100%"}
                jdhead={"Purpose of MongoDB"}
                jdli1={
                  "MongoDB has cultivated a reputation as a versatile, flexible database."
                }
                jdli2={"MongoDB is a non-relational database system."}
                jdli3={
                  "MongoDB can map objects from any programming language, ensuring easy implementation and maintenance."
                }
                jdli4={
                  "MongoDB can serve diverse sets of data and multiple purposes within a single application."
                }
                jdli5={
                  "Native aggregation allows users to extract and transform data from the database."
                }
              />
              <h3 className="heading3">Version Control</h3>
              <AccordionComp
                title={"Git & GitHub"}
                titleWidth={"100%"}
                jdhead={"Purpose of Git & GitHub"}
                jdli1={"Overview of version control"}
                jdli2={"Importance in software development"}
                jdli3={"Installing Git"}
                jdli4={"Initializing a Git repository"}
                jdli5={"Basic commands: add, commit, status"}
                jdli6={"Understanding branches"}
                jdli7={"Creating and switching branches"}
                jdli8={"Cloning remote repositories"}
                jdli9={"Pushing and pulling changes"}
                jdli10={"Basics of pull requests"}
                jdli11={"Creating a GitHub account"}
                jdli12={"Basics of GitHub repositories and issues"}
                jdli13={"Pushing changes to GitHub"}
              />
            </div>
            <div className="course-AccordionComp-right">
              <Card key={"Secondary"} className="mb-2 simplecard">
                <Card.Header
                  style={{ backgroundColor: "#00e1ff" }}
                  className="simplecard-title"
                >
                  Program Highlights
                </Card.Header>
                <Card.Body className="simplecard-desc">
                  {/* <Card.Title>Title</Card.Title> */}
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid blue" }}
                    >
                      <VscPass style={{ color: "blue" }} />
                    </div>
                    <div className="text">Live Session across 5 Months</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid green" }}
                    >
                      <VscPass style={{ color: "green" }} />
                    </div>
                    <div className="text">100% Job Ready</div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid orange" }}
                    >
                      <VscPass style={{ color: "orange" }} />
                    </div>
                    <div className="text">
                      Industry Capstone Projects & Case studies
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="icon"
                      style={{ borderRight: "1px solid purple" }}
                    >
                      <VscPass style={{ color: "purple" }} />
                    </div>
                    <div className="text">24*7 Support</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
        {/* Curriculum end */}
        {/* Start Technology we use Section  */}
        <h1 className="heading1">Skills Covered</h1>
        <ul className="skills-covered row flex-wrap">
          <li>Agile</li>
          <li>HTTP</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>Expressjs</li>
          <li>ReactJS</li>
          <li>Frontend Development</li>
          <li>Backend Development</li>
          <li>API Testing with Postman</li>
          <li>Full Stack Development</li>
        </ul>
        {/* End Technology we use Section  */}
        {/* Start Technology we use Section  */}
        <section className="technologies-weuse-container section ">
          <h1 className="heading1">Tools Covered</h1>
          <div className="row flex-wrap">
            <TechCard heading={"JAVASCRIPT"} icon={<DiJavascript1 />} />
            <TechCard
              heading={"ReactJS"}
              icon={<FaReact style={{ color: "#61DBFB" }} />}
            />
            <TechCard
              heading={"NodeJS"}
              icon={<FaNodeJs style={{ color: "#3C873A" }} />}
            />
            <TechCard
              heading={"ExpressJS"}
              icon={<SiExpress style={{ color: "" }} />}
            />
            <TechCard
              heading={"MongoDB"}
              icon={<SiMongodb style={{ color: "#3C873A" }} />}
            />
            <TechCard
              heading={"Git & GitHub"}
              icon={<BsGit style={{ color: "#F14E32" }} />}
            />
            <TechCard
              heading={"HTML"}
              icon={<ImHtmlFive style={{ color: "#FF5733" }} />}
            />
            <TechCard
              heading={"CSS"}
              icon={<ImCss3 style={{ color: "#2965f1" }} />}
            />
          </div>
        </section>
        {/* End Technology we use Section  */}
           
           <Hiringpartners/>
      
        <section id="apply" className="section apply-course">
          <div className="home-services-header">
            <h1 className="section-title">
              <span>Apply</span> Here
            </h1>
          </div>
          <ApplyCourse />
        </section>
      </main>
    </>
  );
};

export default Mern;
