import React from "react";
import "./Registration.css";
import { useState } from "react";
import axios from "axios";
import AlertComp from "../../components/Alert/Alert";

function Register() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState();
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");



  async function submit(e) {
    e.preventDefault();

    try {
      await axios
        .post("https://icoesolutionserver.onrender.com/api/register", {
          name,
          phone,
          email,
          dob,
          company,
          designation,
          message
        })
        .then((resp) => {
          console.log(resp.data)
          setResponse(resp.data.message)
          setName("")
          setEmail("")
          setPhone("")
          setDob("")
          setDesignation("")
          setCompany("")
          setMessage("")

        })
        .catch((e) => {
          alert("Something went wrong");  
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
        {/* <h1 className="heading3">Register</h1> */}
      <div className="contact-page-container">
        {/* <form action="POST" className="form" onSubmit={submit}>
          <input
            aria-label="Name"
            type="name"
            className="form-control"
            id="name"
            placeholder="Enter Full Name"
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input
            aria-label="Phone"
            type="number"
            className="form-control"
            placeholder="Enter Phone Number"
            required
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <input
            aria-label="Email"
            type="email"
            className="form-control"
            id="email"
            placeholder="Enter email"
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <input
            aria-label="DOB"
            type="date"
            className="form-control"
            placeholder="Date of Birth"
            onChange={(e) => {
              setDob(e.target.value);
            }}
          />
          <input
            aria-label="Name"
            type="text"
            className="form-control"
            placeholder="Current Institute / Company"
            required
            onChange={(e) => {
              setCompany(e.target.value);
            }}
          />
          <input
            aria-label="Name"
            type="name"
            className="form-control"
            id="name"
            placeholder="Your Designation / Role"
            onChange={(e) => {
              setRole(e.target.value);
            }}
          />

          <button type="submit">Create Account</button>
        </form> */}
        <div className="contactForm">
        <form action='POST' onSubmit={submit}>
					<h2>Register</h2>
					<div class="inputBox">
						<input type="text" name="" required="true" onChange={(e)=>{setName(e.target.value)}} value={name}/>
						<span>Full Name</span>
					</div>
					<div class="inputBox">
						<input type="number" name="" required="true" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
						<span>Phone</span>
					</div>
					<div class="inputBox">
						<input type="email" name="" required="true" onChange={(e)=>{setEmail(e.target.value)}} value={email}/>
						<span>Email</span>
					</div>
					<div class="inputBox">
						<input type="date" name="" onChange={(e)=>{setDob(e.target.value)}} value={dob}/>
					</div>
					<div class="inputBox">
						<input type="text" name="" required="true" onChange={(e)=>{setCompany(e.target.value)}} value={company}/>
						<span>Current Company</span>
					</div>
					<div class="inputBox">
						<input type="text" name="" required="true" onChange={(e)=>{setDesignation(e.target.value)}} value={designation}/>
						<span>Your Designation</span>
					</div>
					<div class="inputBox">
						<textarea  required="true" onChange={(e)=>{setMessage(e.target.value)}} value={message}></textarea>
						<span>Type your Message...</span>
					</div>
					{response && (<AlertComp message={response}/>)}

					<div class="inputBox">
						<input type="submit" value="Register" />
					</div>
				</form>
        </div>
      </div>
    </>
  );
}

export default Register;
