import React from "react";
import "./MobileDevelopment.css";
import TechCard from "../../../components/cards/TechCard";
import TitleTextCard from "../../../components/cards/TitleTextCard";
import { NavLink } from "react-router-dom";
import ContactSection from "../../../components/contact/ContactSection";
import TopSection from "../../../components/cards/top/TopSection";
import { BiLogoFlutter } from "react-icons/bi";
import { TbBrandReactNative } from "react-icons/tb";
import { SiIonic } from "react-icons/si";
import { SiCivicrm } from "react-icons/si";
import { GrMysql } from "react-icons/gr";

const MobileDevelopment = () => {
  return (
    <>
      <div className="mobiledev-top">
      <TopSection
        heading={"Mobile App Development"}
        text={
          "Population is preferring mobile apps as their favorite touchpoint."
        }
        to={"/contact"}
      />
      </div>

      <main className="container">
        {/* Start Custom Mobile App Section  */}
        <h3 className="heading3">Custom Mobile App Development Services</h3>
        <section className=" webdevelopment-custom-container section container">
          <div className="content">
            <div className="content-left">
              <h1>Mobile App Development</h1>
              <p>
                The mobile app development market is growing massively. In this
                digital transformation dedicated era, investing in a mobile app
                is a golden opportunity for businesses. In the third quarter of
                2021, users downloaded 27.6 billion apps from Google Play and
                8.1 billion downloads happened on the Apple App Store. Although
                the competition is high for a new entrant, not supporting a
                mobile app could be a big mistake given that most of the world’s
                population is already preferring mobile apps as their favorite
                touchpoint.
              </p>
            </div>

            <figure className="content-right">
              <img
                src="assets/service/mobile/app.jpg"
                alt="Shiv Shakti Gurukulam"
              />
            </figure>
          </div>
        </section>
        {/* End Custom Website Section  */}

        {/* Start Technology we use Section  */}
        <h1 className="heading1">Key Approaches to Mobile App Development</h1>
        <p className="para1">
          Before investing in a mobile app, a business must decide the approach
          they will follow to build an app. The most commonly known approaches
          include — native apps, cross-platform apps, hybrid apps, progressive
          web apps, and rapid mobile app development.
        </p>
        <section className=" section center">
          <div className="it-staffing-services">
            <div className="card2-item article">
              <div className="card2-info">
                <h1>Native App</h1>
                <h4 className="heading4">
                  UTILIZING PLATFORM-SPECIFIC SERVICE
                </h4>
                <p>
                  Our native app is a testament to our commitment to providing
                  the best possible user experience. It is meticulously crafted
                  to cater to the needs and preferences of our target audience.
                  With a user-friendly interface and a focus on delivering
                  feature-rich functionality.
                </p>
                <NavLink className="cta" to={"/contact"}>
                  Contact{" "}
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/mobile/native.jpg" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item article">
              <div className="card2-info">
                <h1>Cross-Platform App</h1>
                <h4 className="heading4">versatility and cost-efficiency</h4>
                <p>
                  Our cross-platform app is a smart and cost-effective solution
                  for businesses looking to reach a wide and diverse audience.
                  It eliminates the need for separate development efforts for
                  iOS and Android, allowing us to deliver a unified and seamless
                  user experience.
                </p>
                <NavLink className="cta" to={"/contact"}>
                  Get a Call
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/mobile/mcpa.jpg" alt="img" />{" "}
              </div>
            </div>
            <div className="card2-item article">
              <div className="card2-info">
                <h1>Hybrid App</h1>
                <h4 className="heading4">ONE APP - MULTIPLE PLATFORMS</h4>

                <p>
                  Our hybrid app is a versatile solution that bridges the gap
                  between native apps and web apps. It combines the best of both
                  worlds, offering a consistent user experience across iOS and
                  Android devices. With a single codebase, we deliver a unified
                  app that caters to a wide range of users.
                </p>
                <NavLink className="cta" to={"/contact"}>
                  Say Hello{" "}
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/mobile/hybrid.jpg" alt="img" />{" "}
              </div>
            </div>

            <div className="card2-item article">
              <div className="card2-info">
                <h1>Progressive Web App</h1>
                <h4 className="heading4">web-based applications</h4>

                <p>
                  Our Progressive Web App (PWA) is a game-changer in the digital
                  landscape, offering a versatile and user-friendly solution for
                  businesses and their audiences. Unlike traditional native
                  apps, PWAs require no installation and can be accessed
                  instantly through a web browser.
                </p>
                <NavLink className="cta" to={"/contact"}>
                  Connect{" "}
                </NavLink>
              </div>
              <div className="card2-img">
                <img src="assets/service/mobile/pwa.png" alt="img" />{" "}
              </div>
            </div>
          </div>
        </section>
        {/* End Technology we use Section  */}

        {/* Start Technology we use Section  */}
        <h1 className="heading1">Technologies we use</h1>
        <section className="technologies-weuse-container section row flex-wrap center">
          <TechCard heading={"React Native"} icon={<TbBrandReactNative />} />
          <TechCard heading={"Flutter"} icon={<BiLogoFlutter />} />
          <TechCard heading={"Ionic"} icon={<SiIonic />} />
          <TechCard heading={"MySQL"} icon={<GrMysql />} />
          <TechCard heading={"CRM"} icon={<SiCivicrm />} />
        </section>
        {/* End Technology we use Section  */}

        {/* Start Why choose us container  */}
        <h1 className="heading1">Why choose us</h1>
        <section className="why-choose-us-container section ">
          <TitleTextCard heading={"Maintenance"} text={"Hello Text"} />
          <TitleTextCard
            heading={"Custom web development"}
            text={"Hello Text"}
          />
          <TitleTextCard
            heading={"Use of latest Framework & Technology"}
            text={"Hello Text"}
          />
          <TitleTextCard
            heading={"Transparent & Flexible Process"}
            text={"Hello Text"}
          />
          <TitleTextCard
            heading={"Strategic & Asured Quality"}
            text={"Hello Text"}
          />
        </section>
        {/* End Why choose us container  */}
      </main>

      {/* Start Contact form container  */}
      <section className="mobile-contact-section">
        <ContactSection
          text={
            "With our expertise in Healthcare, FinTech, e-commerce, and other business areas, we can take care of your project at any stage. Whether it is a process from scratch or already embedded into the existing process - a web, mobile, or any software development solution will be brought to perfection. For this purpose, we assemble a team of strong professionals for each project."
          }
        />
      </section>
      {/* End Our Offerings container  */}
    </>
  );
};

export default MobileDevelopment;
