import React, { useState } from "react";


import "./HoverComponentView.css";


import HoverCard from "../cards/HoverCard";

const HoverComponentView = () => {
  const [isDefault, setIsDefault] = useState(true);
  const [isHealthCare, setIsHealthCare] = useState(false);
  const [isEcomm, setIsEcomm] = useState(false);
  const [isEducation, setIsEducation] = useState(false);
  const [isRealEstate, setIsRealEstate] = useState(false);
  const [isMedia, setIsMedia] = useState(false);
  const [isLogistics, setIsLogistics] = useState(false);
  const [isTourism, setIsTourism] = useState(false);

  // Health Care Function Starts
  function healthCareEnter() {
    setIsHealthCare(true);
    setIsDefault(false);
  }
  function healthCareLeave() {
    setIsHealthCare(false);
    setIsDefault(true);
  }
  // Health Care Function Ends

  // Ecomm Function Starts
  function EcommEnter() {
    setIsEcomm(true);
    setIsDefault(false);
  }
  function EcommLeave() {
    setIsEcomm(false);
    setIsDefault(true);
  }
  // Ecomm Function Ends

  // Education Function Starts
  function EducationEnter() {
    setIsEducation(true);
    setIsDefault(false);
  }
  function EducationLeave() {
    setIsEducation(false);
    setIsDefault(true);
  }
  // Education Function Ends

  // Real Estate Function Starts
  function RealEstateEnter() {
    setIsRealEstate(true);
    setIsDefault(false);
  }
  function RealEstateLeave() {
    setIsRealEstate(false);
    setIsDefault(true);
  }
  // Real Estate Function Ends

  // Real Media Function Starts
  function MediaEnter() {
    setIsMedia(true);
    setIsDefault(false);
  }
  function MediaLeave() {
    setIsMedia(false);
    setIsDefault(true);
  }
  // Real Media Function Ends

  // Real Logistics Function Starts
  function LogisticsEnter() {
    setIsLogistics(true);
    setIsDefault(false);
  }
  function LogisticsLeave() {
    setIsLogistics(false);
    setIsDefault(true);
  }
  // Real Logistics Function Ends

  // Real Tourism Function Starts
  function TourismEnter() {
    setIsTourism(true);
    setIsDefault(false);
  }
  function TourismLeave() {
    setIsTourism(false);
    setIsDefault(true);
  }
  // Real Tourism Function Ends

  return (
    <>
      <div className="hoverComponent-container">
        <ul className="hoverComponent-left">
          <li onMouseEnter={healthCareEnter} onMouseLeave={healthCareLeave}>
            Health Care
          </li>
          <li onMouseEnter={EcommEnter} onMouseLeave={EcommLeave}>
            Ecomm Solutions
          </li>
          <li onMouseEnter={EducationEnter} onMouseLeave={EducationLeave}>
            Education
          </li>
          <li onMouseEnter={RealEstateEnter} onMouseLeave={RealEstateLeave}>
            Real Estate
          </li>
          <li onMouseEnter={MediaEnter} onMouseLeave={MediaLeave}>
            Media & Entertainment
          </li>
          <li onMouseEnter={LogisticsEnter} onMouseLeave={LogisticsLeave}>
            Logistics
          </li>
          <li onMouseEnter={TourismEnter} onMouseLeave={TourismLeave}>
            Tourism
          </li>
        </ul>

        <div className="hoverComponent-right">
          {isDefault && (
            <HoverCard
              heading={"Industries we serve"}
              text={`Hover on buttons to see details`}
              bgClass={'default-bg'}
            />
            // <Card3
            // src={'assets/training/fullstack.png'}
            //   title={"Default"}
            //   text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo,
            // placeat.`}
            // />
          )}
          {isHealthCare && (
            <HoverCard
              heading={"Health Care"}
              text={`Revolutionize healthcare with our specialized web development service. We create tailored, secure, and user-friendly websites to enhance patient engagement, streamline medical processes, and elevate the overall digital experience in the health sector.`}
        bgClass={'health-bg'}
            />
          )}
          {isEcomm && (
            <HoverCard
              heading={"Ecomm Solutions"}
              text={`Elevate your online business with our expert e-commerce web development service. We specialize in creating robust, user-friendly websites that enhance the shopping experience, drive sales, and optimize your digital storefront for success in the competitive e-commerce landscape.`}
        bgClass={'ecom-bg'}
            />
          )}
          {isEducation && (
            <HoverCard
              heading={"Education"}
              text={`Transform education with our comprehensive web development service. We design and develop innovative websites tailored for the education sector, fostering interactive learning experiences, facilitating seamless communication, and empowering institutions to thrive in the digital age."`}
      bgClass={'edu-bg'}
            />
          )}
          {isRealEstate && (
            <HoverCard
              heading={"Real Estate"}
              text={`Elevate your real estate presence with our tailored web development service. We craft visually stunning, user-friendly websites that showcase properties, facilitate seamless navigation, and provide a compelling online experience, empowering your real estate business to thrive in the digital marketplace.`}
        bgClass={'realestate-bg'}

            />
          )}
          {isMedia && (
            <HoverCard
              heading={"Media & Entertainment"}
              text={`Unleash the power of digital entertainment with our cutting-edge web development service. We specialize in creating dynamic and immersive websites that captivate audiences, deliver engaging content, and enhance user interaction, ensuring your media and entertainment brand stands out in the digital landscape.rem ipsum dolor sit amet consectetur adipisicing elit. Illo,
        placeat.`}
        bgClass={'media-bg'}

            />
          )}
          {isLogistics && (
            <HoverCard
              heading={"Logistics"}
              text={`Optimize your logistics operations with our specialized web development service. We create robust and efficient websites tailored for the logistics industry, streamlining processes, enhancing visibility, and facilitating seamless communication, ensuring your business moves forward with precision in the digital era.`}
        bgClass={'logistics-bg'}
            />
          )}
          {isTourism && (
            <HoverCard
              heading={"Tourism"}
              text={`Elevate your tourism business with our bespoke web development service. We design immersive websites that inspire wanderlust, provide seamless booking experiences, and showcase destinations with rich visuals, ensuring your tourism brand stands out and attracts adventurers in the digital realm.`}
        bgClass={'tourism-bg'}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HoverComponentView;
