import React from 'react'
import './Cards.css'

const IconTextCard = ({icon,heading,text}) => {
  return (
    <>
    <div className="icon-text-card">
              {icon}
              <h3 className="heading">{heading}</h3>
              <p>
                {text}
              </p>
            </div>
    </>
  )
}

export default IconTextCard